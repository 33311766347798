import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { tree } from 'd3-hierarchy';
import { drag } from 'd3-drag';

import { useOmcDictionary, useOmcJsonRoot } from '../../../hooks/OmcDictionaryContext';
// import useTraceUpdate from '../../../hooks/useTraceUpdate';

import {
    nodeParams,
    fontHeight,
    nodeSpaceVertical,
    minSpaceHorizontal,
    statusColor,
    canDrop,
} from './omcJsonDefaults.mjs';

import {
    createD3Node,
    omcJsonParams,
    diagonal,
    exitOffsetLink,
    entryOffsetLink,
    dragNodePosition,
    treeHeight,
    dragStarted,
    dragCoords,
    dragNodeTransform,
    dragged,
    hasCollided,
    omcD3id,
} from '../../../services/vocabulary/d3Utilities.mjs';

let altKey = false;
const nodeData = ((n) => (n.data.type === 'omc:Schema' ? n.data.value : n.data));

function OmcJsonTreeDiagram(props) {
    const {
        updateSideBar = () => {}, // Function to update sidebar
        updateDragNode = () => {}, // Call to change the draggable node
        updateChart = () => {}, // Call to update the display
        displayMessage = () => {}, // Display a message to the user such as a warning
        displayContextMenu = () => {}, // Display a context menu for the user
        triggerRender = null, // Forces a re-render of the tree after table has updated
        treeId = 'omcJsonTree', // Unique Id for the tree container in DOM
        dragNodeId = { nodeId: null, location: null }, // Id of the current draggable node
        containerDimensions = { width: 0, height: 0 }, // The dimensions of the container for the tree
        zoomPosition = { k: 1, x: 0, y: 0 }, // The current zoom position of the tree
    } = props;

    // console.log('Render: OmcJsonTreeDiagram');
    // useTraceUpdate(props);

    const root = useOmcJsonRoot();
    const [currentDragNode, setCurrentDragNode] = useState(null);

    const nodeRender = omcJsonParams(nodeParams);
    const omcDictionary = useOmcDictionary();
    const { width: containerWidth, height: containerHeight } = containerDimensions;

    // Holding altKey slows down the transition
    document.addEventListener('keydown', (e) => { altKey = e.altKey; });
    document.addEventListener('keyup', (e) => { altKey = e.altKey; });

    async function contextAction(action, node) {
        console.log(`Executing ${action} on node ${node.data.id}`);

        if (action === 'cancelDrag') {
            updateDragNode(null); // Clear the dragged node
            return;
        }

        // const findParentProperty = ((n) => (
        //     (n.data.type === 'omc:Entity' || n.data.type === 'omc:Property' || !n.parent)
        //         ? n.data
        //         : findParentProperty(n.parent)
        // ));

        // Pass the action to the reducer for processing
        const updateResponse = omcDictionary.changeOMCRequest(action, {
            // topProperty: findParentProperty(node.parent),
            parent: node.parent.data,
            node: node.data,
        });

        if (updateResponse.error) {
            displayMessage(updateResponse.error);
        } else {
            await updateChart({
                action: updateResponse.action,
            }); // Do a database update
            if (action === 'moveProperty' || action === 'moveControlledValue' || action === 'copyProperty') {
                const dragId = node.data.id;
                updateDragNode(dragId, node); // Clear the dragged node
            }
            updateSideBar(); // Update the sidebar so that if it is showing a removed node, it will be cleared
        }
    }

    // const parentProperty = ((n) => {
    //     if (n.data.type === 'omc:Schema') return n.data;
    //     if (n.data.type === 'omc:Property' || n.data.type === 'omc:Entity' || n.data.type === 'omc:Root') return n.data;
    //     return parentProperty(n.parent);
    // });
    //
    // // Find the parent node that is an omc:Property
    // const controlledValueLabel = ((p, label) => {
    //     const parentType = p.data.type;
    //     return (parentType === 'omc:ControlledValue')
    //         ? controlledValueLabel(p.parent, [p.data.label, ...label])
    //         : label;
    // });

    async function dragEnded(e, d) {
        if (!d.draggable) return;

        // Was the dragged node dropped onto another node
        const [dragX, dragY] = dragCoords(d);
        const { nodes } = d;
        const shape = nodeRender.shape(d);

        const { type } = d.data; // Only nodes of the correct type can be dropped on
        const candidateType = canDrop[type];
        const candidateNodes = nodes.filter((n) => candidateType.includes((nodeData(n)).type));

        const collidedNode = candidateNodes.find((n) => (
            hasCollided(n.x - dragX, n.y - dragY, shape) // Adjust for the dragged position and shape
        ));

        if (collidedNode) { // The draggable node has not been dropped over another node
            const actionVerb = (d.data.type === 'omc:Property' || d.data.type === 'omc:Entity' || d.data.type === 'omc:Container')
                ? 'insertProperty' : 'insertControlledValue';
            const updateRequest = omcDictionary.changeOMCRequest(actionVerb, {
                parent: { schemaId: collidedNode.d3id, ...collidedNode.data },
                node: { schemaId: d.id, ...d.data },
            });

            // Check the results of any collision and do appropriate updates of warnings
            if (updateRequest?.error) {
                displayMessage(updateRequest.error); // Display any warning or error message
            } else if (updateRequest) {
                const dbResponse = await updateChart({ // Update the database
                    action: updateRequest.action,
                    openChildId: { [collidedNode.d3id]: true },
                });

                select(`#${collidedNode.d3id} rect`) // Un-highlight the collided node
                    .attr('filter', null);

                updateDragNode(null, collidedNode); // Clear the dragged node
                console.log(`This was dropped onto ${collidedNode.d3id} - ${collidedNode.data.id}`);
            }
        }
    }

    // If the drag node changes, calculate it's starting position, or set to null if removed
    useEffect(() => {
        const { nodeId, location } = dragNodeId || {};
        console.log(`Drag Node has changed: ${nodeId}`);

        if (!nodeId) {
            setCurrentDragNode(null); // Remove the draggable node if there was one
            return;
        }

        // Position the draggable node at the specified location, or the center of the screen
        const { xPos: x, yPos: y } = dragNodePosition(zoomPosition, containerWidth, containerHeight);
        const d3Node = location
            ? createD3Node(nodeId, omcDictionary, omcD3id, { ...location, draggable: true })
            : createD3Node(nodeId, omcDictionary, omcD3id, {
                y, y0: y, x, x0: x, draggable: true,
            });
        setCurrentDragNode(d3Node);
    }, [dragNodeId]);

    useEffect(() => {
        if (!root) return; // Render after the root node has been initialized

        // Select the main container using the tree's id
        const svg = select(`#${treeId}-container`); // The g that zoom is on.

        // Select the g container for the graphs nodes
        const gNode = select(`#${treeId}-container`)
            .select('g.treeNode');

        // Select the g container for the graphs links
        const gLink = select(`#${treeId}-container`)
            .select('g.treeLink');

        function update() {
            const transition = svg.transition() // Hold the alt key to slow down the transition
                .duration(altKey ? 5000 : 500);

            const nodes = root.descendants();
            const links = root.links();

            // Resize the tree based on layout and screen size
            const nodeSpaceHorizontal = Math.max(containerWidth / treeHeight(root), minSpaceHorizontal);
            const d3Tree = tree() // Define the tree layout and the shape for links.
                .nodeSize([nodeSpaceVertical, nodeSpaceHorizontal]);
            d3Tree(root); // Compute the new tree layout.

            // If there is a valid draggable node, add it to nodes to be rendered.
            console.log(`Current Drag Node: ${currentDragNode ? currentDragNode.data.id : 'None'}`);
            // Node must exist in the dictionary to be draggable
            if (currentDragNode && currentDragNode.draggable && omcDictionary.getNode(currentDragNode.data.id)) {
                nodes.push({ ...currentDragNode, ...{ nodes } });
            }

            const node = gNode.selectAll('g')
                .data(nodes, (d) => d.d3id);

            node.exit()
                .transition(transition)
                .remove()
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    const dragExit = d.draggable ? (dragNodeId.location || d) : currentDragNode; // Draggable nodes stay stationary
                    const exitPoint = d.parent ? d.parent : { x: dragExit.x, y: dragExit.y - shape.width }; // Use a node parent, or the source passed in
                    const s = exitOffsetLink({
                        x: exitPoint.x - shape.height / 2,
                        y: exitPoint.y,
                    }, shape);
                    return `translate(${s.y}, ${s.x})`;
                })
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0)
                .select('.status-rect')
                .attr('stroke-opacity', 0);

            // Enter any new nodes at the parent's previous position.
            const nodeEnter = node.enter()
                .append('g')
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    const s = d.parent
                        ? exitOffsetLink({
                            x: (d.parent.x0 || d.parent.x) - shape.height / 2,
                            y: (d.parent.y0 || d.parent.y),
                        }, shape) // Spawn new nodes at link exit point
                        : { y: d.y - shape.width / 2, x: d.x - shape.height / 2 };
                    return `translate(${s.y}, ${s.x})`;
                })
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0)
                .call(drag()
                    .clickDistance(100)
                    .on('start', dragStarted)
                    .on('drag', function dragy(event, dragNode) {
                        dragged.call(this, {
                            event, dragNode, nodeRender, canDrop,
                        });
                    })
                    .on('end', async function dragEnd(e, d) {
                        if (d.draggable) await dragEnded.call(this, e, d);
                    }))
                .on('mouseover', (e, d) => {
                    if (!e.ctrlKey && !d.draggable) updateSideBar(d.data.id); // If Ctrl not held and not dragging
                });

            // Transition nodes to their new position.
            node.merge(nodeEnter)
                .on('contextmenu', (e, d) => { // Binding this in the merge ensure draggableNode is in scope
                    e.preventDefault();
                    displayContextMenu({
                        event: e,
                        items: nodeRender.contextMenu(d),
                        onClick: contextAction,
                        node: d,
                    });
                })
                .attr('id', (d) => d.d3id)
                .transition(transition)
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    return d.draggable // Draggable nodes do not transition, will remain in same position
                        ? dragNodeTransform(d, nodeRender)
                        : `translate(${d.y - shape.width / 2}, ${d.x - shape.height / 2})`;
                })
                .attr('fill-opacity', 1)
                .attr('stroke-opacity', 1);

            const mainRect = nodeEnter.append('rect')
                .attr('width', (d) => nodeRender.shape(d).width)
                .attr('height', (d) => nodeRender.shape(d).height)
                .attr('ry', (d) => nodeRender.shape(d).radius)
                .attr('rx', (d) => nodeRender.shape(d).radius);

            const statusRect = nodeEnter.append('rect')
                .attr('class', 'status-rect')
                .attr('width', (d) => nodeRender.shape(d).height / 2)
                .attr('height', (d) => nodeRender.shape(d).height)
                .attr('ry', (d) => nodeRender.shape(d).radius)
                .attr('rx', (d) => nodeRender.shape(d).radius)
                .attr('stroke-opacity', 1);

            const labelText = nodeEnter.append('text')
                .attr('x', (d) => (nodeRender.shape(d).width) / 2)
                .attr('y', (d) => (nodeRender.shape(d).height / 2) + fontHeight / 2)
                .attr('text-anchor', 'middle');

            node.select('.status-rect')
                .merge(statusRect)
                .transition(transition)
                .attr('opacity', (d) => (nodeRender.statusColor(d, statusColor, omcDictionary) ? 1 : 0)) // Hide rect if no status
                .attr('fill', (d) => nodeRender.statusColor(d, statusColor, omcDictionary)); // Set color of status rectangle

            node.select('rect')
                .merge(mainRect)
                .transition(transition)
                .attr('id', (d) => (d.draggable ? 'dragNode' : null))
                .attr('fill', (d) => (nodeRender.fillColor(d)));

            node.select('text')
                .merge(labelText)
                .transition(transition)
                .text((d) => nodeRender.prefLabel(d, omcDictionary));

            const expandCircle = nodeEnter.append('circle')
                .attr('cx', (d) => (nodeRender.shape(d).width))
                .attr('cy', (d) => (nodeRender.shape(d).height) / 2)
                .attr('fill', (d) => nodeRender.expand(d));

            node.select('circle')
                .merge(expandCircle)
                .on('click', (e, d) => { // Binding this after a merge ensures draggableNode is in scope
                    e.stopPropagation();
                    updateChart({
                        openChildId: { [d.d3id]: !d.children },
                    }); // Toggle the open/closed state of the node
                })
                .transition(transition)
                .attr('r', (d) => (d._children || d.children ? 9 : 0));

            // Update the links…
            const link = gLink.selectAll('path')
                .data(links, (d) => `${d.source.d3id}-${d.target.d3id}`); // return d.target.id

            // Enter any new links at the parent's previous position.
            const linkEnter = link.enter()
                .append('path')
                .attr('id', (d) => `${d.source.d3id}-${d.target.d3id}`)
                .attr('d', (d) => {
                    const s = exitOffsetLink(
                        { x: d.source.x0 || d.source.x, y: d.source.y0 || d.source.y },
                        nodeRender.shape(d.source),
                    );
                    return diagonal({
                        source: s, target: s,
                    });
                });

            // Transition links to their new position.
            link.merge(linkEnter)
                .transition(transition)
                .attr('d', (d) => (
                    diagonal({
                        source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                        target: entryOffsetLink(d.target, nodeRender.shape(d.target)),
                    })
                ));

            // Transition exiting nodes to the position of the clicked node that is closing.
            link.exit()
                .transition(transition)
                .remove()
                .attr('d', (d) => (
                    diagonal({
                        source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                        target: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                    })
                ));

            // Stash the old positions for transition.
            root.eachBefore((d) => {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        }

        update();
    }, [root, currentDragNode, triggerRender, containerDimensions]);

    return null;
}

OmcJsonTreeDiagram.propTypes = {
    updateSideBar: PropTypes.func, // Function to update sidebar
    updateDragNode: PropTypes.func, // Call to change the draggable node
    updateChart: PropTypes.func, // Call to update the vocabulary data
    displayMessage: PropTypes.func, // Display a message to the user such as a warning
    displayContextMenu: PropTypes.func, // Display a context menu for the user
    triggerRender: PropTypes.number, // Forces a re-render of the tree after table has updated
    treeId: PropTypes.string, // Unique Id for the tree container in DOM
    dragNodeId: PropTypes.shape({ // Detail for draggable node, and it's location for entering/exiting
        nodeId: PropTypes.string,
        location: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }),
    containerDimensions: PropTypes.shape({ // The dimensions of the container for the tree
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    zoomPosition: PropTypes.shape({ // The current zoom position of the tree
        k: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
    }),
};

export default OmcJsonTreeDiagram;
