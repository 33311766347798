import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { tree } from 'd3-hierarchy';
import { drag } from 'd3-drag';

import {
    nodeParams,
    fontHeight,
    nodeSpaceVertical,
    minSpaceHorizontal,
    statusColor,
    canDrop,
} from './skosDefaults.mjs';

import { schemeBranchId } from '../../../services/vocabulary/skosD3Tree.mjs';
import {
    createD3Node,
    diagonal,
    exitOffsetLink,
    entryOffsetLink,
    dragNodePosition,
    treeHeight,
    dragStarted,
    dragCoords,
    dragNodeTransform,
    dragged,
    hasCollided,
    skosD3id,
    skosParams,
} from '../../../services/vocabulary/d3Utilities.mjs';
import { useSkosDictionary, useSkosRoot } from '../../../hooks/SkosDictionaryContext';
// import useTraceUpdate from '../../../hooks/useTraceUpdate';

let altKey = false;

function SkosTreeDiagram(props) {
    const {
        updateChart = () => {}, // Function to update the chart
        updateSideBar = () => {}, // Function to update sidebar
        updateTable = () => {}, // Function to change the scheme displayed in the table
        updateDragNode = () => {}, // Call to change the draggable node
        displayMessage = () => {}, // Display a message to the user such as a warning
        displayContextMenu = () => {}, // Display a context menu for the user
        selectedScheme = [null], // Array of scheme identifiers to display in table
        triggerRender = null, // Forces a re-render of the tree after table has updated
        treeId = 'omcJsonTree', // Unique Id for the tree container in DOM
        dragNodeId = { nodeId: null, location: null }, // Id of the current draggable node
        containerDimensions = { width: 0, height: 0 }, // The dimensions of the container for the tree
        zoomPosition = { k: 1, x: 0, y: 0 }, // The current zoom position of the tree
    } = props;

    // console.log(`Render: SkosTreeDiagram}`);
    // useTraceUpdate(props);
    // console.log('Skos Tree:', selectedScheme);

    const root = useSkosRoot();
    // const { updateRoot } = useContext(SkosDictionaryContext);
    const [currentDragNode, setCurrentDragNode] = useState(null);

    const nodeRender = skosParams(nodeParams);
    const skosDictionary = useSkosDictionary();
    const { width: containerWidth, height: containerHeight } = containerDimensions;

    // Holding altKey slows down the transition
    document.addEventListener('keydown', (e) => { altKey = e.altKey; });
    document.addEventListener('keyup', (e) => { altKey = e.altKey; });

    // Perform an action on a node in the context menu
    async function contextAction(action, node) {
        // The Drag action is a special case, it is not a database update
        if (action === 'cancelDrag') {
            updateDragNode(null); // Clear the dragged node
            return;
        }
        // The parent node type, influences the action
        const parentType = node.parent.data.type === 'skos:ConceptScheme' ? 'TopConcept' : 'Narrower';
        const actionVerb = `${action}${parentType}`;

        const updateResponse = skosDictionary.changeVocabRequest(actionVerb, {
            parent: node.parent.data,
            concept: node.data,
            schemeId: schemeBranchId(node),
        });
        if (updateResponse.error) {
            displayMessage(updateResponse.error);
        } else {
            // const dbResponse = await updateVocab(updateResponse.action); // Do a database update
            await updateChart({
                action: updateResponse.action,
            }); // Do a database update
            const dragId = node.data.id;
            if (action.includes('Move')) updateDragNode(dragId); // Move action leave draggable node
            updateTable(selectedScheme); // Force update of table
        }
    }

    // Drag acton has ended, check for collision and update the database
    async function dragEnded(e, d) {
        if (!d.draggable) return;

        // Was the dragged node dropped onto another node
        const [dragX, dragY] = dragCoords(d);
        const { nodes } = d;
        const shape = nodeRender.shape(d);

        const { type } = d.data; // Only nodes of the correct type can be dropped on
        const candidateType = canDrop[type];
        const candidateNodes = nodes.filter((n) => (
            n.data ? candidateType.includes(n.data.type) : false
        ));

        const collidedNode = candidateNodes.find((n) => (
            hasCollided(n.x - dragX, n.y - dragY, shape) // Adjust for the dragged position and shape
        ));

        // Check the results of any collision and do appropriate updates of warnings
        if (collidedNode) {
            const actionVerb = collidedNode.data.type === 'skos:ConceptScheme' ? 'conceptAddTopConcept' : 'conceptAddNarrower';
            const updateRequest = skosDictionary.changeVocabRequest(actionVerb, {
                parent: collidedNode.data,
                schemeId: schemeBranchId(collidedNode),
                topConcept: d.data,
                narrower: d.data,
            });
            if (updateRequest?.error) {
                displayMessage(updateRequest.error);
            } else if (updateRequest) {
                // await updateVocab(updateRequest.action, collidedNode.d3id); // Update the database
                const dbResponse = await updateChart({ // Update the database
                    action: updateRequest.action,
                    openChildId: { [collidedNode.d3id]: true },
                });

                select(`#${collidedNode.d3id} rect`)
                    .attr('filter', null);

                updateDragNode(null, collidedNode); // Clear the dragged node
                updateTable(selectedScheme); // Update the table to reflect changes
                console.log(`This was dropped onto ${collidedNode.d3id} - ${collidedNode.data.id}`);
            }
        }
    }

    // If the drag node changes, calculate it's starting position, or set to null if removed
    useEffect(() => {
        const { nodeId, location } = dragNodeId || {};
        const { xPos, yPos } = dragNodePosition(zoomPosition, containerWidth, containerHeight);
        console.log(`Drag Node has changed: ${nodeId}`);

        const d3Node = (!nodeId || location)
            ? null
            : createD3Node(nodeId, skosDictionary, skosD3id, {
                y: yPos, y0: yPos, x: xPos, x0: xPos, draggable: true,
            });
        setCurrentDragNode(d3Node);
    }, [dragNodeId]);

    useEffect(() => {
        if (!root) return; // Render after the root node has been initialized

        // Select the main container using the tree's id
        const svg = select(`#${treeId}-container`); // The g that zoom is on.

        // Select the g container for the graphs nodes
        const gNode = select(`#${treeId}-container`)
            .select('g.treeNode');

        // Select the g container for the graphs links
        const gLink = select(`#${treeId}-container`)
            .select('g.treeLink');

        function update() {
            const transition = svg.transition() // Hold the alt key to slow down the transition
                .duration(altKey ? 5000 : 500);

            const nodes = root.descendants();
            const links = root.links();

            // Resize the tree based on layout and screen size
            const nodeSpaceHorizontal = Math.max(containerWidth / treeHeight(root), minSpaceHorizontal);
            const d3Tree = tree() // Define the tree layout and the shape for links.
                .nodeSize([nodeSpaceVertical, nodeSpaceHorizontal]);
            d3Tree(root); // Compute the new tree layout.

            // If there is a valid draggable node, add it to nodes to be rendered.
            console.log(`Current Drag Node: ${currentDragNode ? currentDragNode.data.id : 'None'}`);
            // Node must exist in the dictionary to be draggable
            if (currentDragNode && currentDragNode.draggable && skosDictionary.getNode(currentDragNode.data.id)) {
                nodes.push({ ...currentDragNode, ...{ nodes } });
            }

            const node = gNode.selectAll('g')
                .data(nodes, (d) => d.d3id);

            node.exit()
                .transition(transition)
                .remove()
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    const dragExit = d.draggable ? (dragNodeId.location || d) : currentDragNode; // Draggable nodes stay stationary
                    const exitPoint = d.parent ? d.parent : { x: dragExit.x, y: dragExit.y - shape.width }; // Use a node parent, or the source passed in
                    const s = exitOffsetLink({
                        x: exitPoint.x - shape.height / 2,
                        y: exitPoint.y,
                    }, shape);
                    return `translate(${s.y}, ${s.x})`;
                })
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0)
                .select('.status-rect')
                .attr('stroke-opacity', 0);

            // Enter any new nodes at the parent's previous position.
            const nodeEnter = node.enter()
                .append('g')
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    const s = d.parent
                        ? exitOffsetLink({
                            x: (d.parent.x0 || d.parent.x) - shape.height / 2,
                            y: (d.parent.y0 || d.parent.y),
                        }, shape) // Spawn new nodes at link exit point
                        : { y: d.y - shape.width / 2, x: d.x - shape.height / 2 };
                    return `translate(${s.y}, ${s.x})`;
                })
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0)
                .call(drag()
                    .clickDistance(100)
                    .on('start', dragStarted)
                    .on('drag', function dragy(event, dragNode) {
                        dragged.call(this, {
                            event, dragNode, nodeRender, canDrop,
                        });
                    })
                    .on('end', async function dragEnd(e, d) {
                        if (d.draggable) await dragEnded.call(this, e, d);
                    }))
                .on('click', (e, d) => {
                    if (d.data.type === 'skos:ConceptScheme') updateTable(d.data.id);
                    if (d.data.type === 'omc:Root') updateTable(skosDictionary.getType('skos:ConceptScheme'));
                })
                .on('mouseover', (e, d) => {
                    if (!e.ctrlKey && !d.draggable) updateSideBar(d.data.id); // If Ctrl not held and not dragging
                });

            // Transition nodes to their new position.
            node.merge(nodeEnter)
                .on('contextmenu', (e, d) => { // Binding this in the merge ensure draggableNode is in scope
                    e.preventDefault();
                    displayContextMenu({
                        event: e,
                        items: nodeRender.contextMenu(d), // The menu list items
                        onClick: contextAction,
                        node: d,
                    });
                })
                .attr('id', (d) => d.d3id)
                .transition(transition)
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    return d.draggable // Draggable nodes do not transition, will remain in same position
                        ? dragNodeTransform(d, nodeRender)
                        : `translate(${d.y - shape.width / 2}, ${d.x - shape.height / 2})`;
                })
                .attr('fill-opacity', 1)
                .attr('stroke-opacity', 1);

            const mainRect = nodeEnter.append('rect')
                .attr('width', (d) => nodeRender.shape(d).width)
                .attr('height', (d) => nodeRender.shape(d).height)
                .attr('ry', (d) => nodeRender.shape(d).radius)
                .attr('rx', (d) => nodeRender.shape(d).radius);

            const statusRect = nodeEnter.append('rect')
                .attr('class', 'status-rect')
                .attr('width', (d) => nodeRender.shape(d).height / 2)
                .attr('height', (d) => nodeRender.shape(d).height)
                .attr('ry', (d) => nodeRender.shape(d).radius)
                .attr('rx', (d) => nodeRender.shape(d).radius)
                .attr('stroke-opacity', 1);

            const labelText = nodeEnter.append('text')
                .attr('x', (d) => (nodeRender.shape(d).width) / 2)
                .attr('y', (d) => (nodeRender.shape(d).height / 2) + fontHeight / 2)
                .attr('text-anchor', 'middle');

            node.select('.status-rect')
                .merge(statusRect)
                .transition(transition)
                .attr('opacity', (d) => (nodeRender.statusColor(d, statusColor, skosDictionary) ? 1 : 0)) // Hide rect if no status
                .attr('fill', (d) => nodeRender.statusColor(d, statusColor, skosDictionary)); // Set color of status rectangle

            node.select('rect')
                .merge(mainRect)
                .transition(transition)
                .attr('id', (d) => (d.draggable ? 'dragNode' : null))
                .attr('fill', (d) => (nodeRender.fillColor(d)));

            node.select('text')
                .merge(labelText)
                .transition(transition)
                .text((d) => nodeRender.prefLabel(d, skosDictionary));

            const expandCircle = nodeEnter.append('circle')
                .attr('cx', (d) => (nodeRender.shape(d).width))
                .attr('cy', (d) => (nodeRender.shape(d).height) / 2)
                .attr('fill', (d) => nodeRender.expand(d));

            node.select('circle')
                .merge(expandCircle)
                .on('click', (e, d) => { // Binding this after a merge ensures draggableNode is in scope
                    e.stopPropagation();
                    updateChart({
                        openChildId: { [d.d3id]: !d.children },
                    }); // Toggle the open/closed state of the node
                    // nodeClicked(e, d);
                    // updateRoot(skosDictionary, root);
                    // update();
                })
                .transition(transition)
                .attr('r', (d) => (d._children || d.children ? 9 : 0));

            // Update the links…
            const link = gLink.selectAll('path')
                .data(links, (d) => `${d.source.d3id}-${d.target.d3id}`); // return d.target.id

            // Enter any new links at the parent's previous position.
            const linkEnter = link.enter()
                .append('path')
                .attr('id', (d) => `${d.source.d3id}-${d.target.d3id}`)
                .attr('d', (d) => {
                    const s = exitOffsetLink(
                        { x: d.source.x0 || d.source.x, y: d.source.y0 || d.source.y },
                        nodeRender.shape(d.source),
                    );
                    return diagonal({
                        source: s,
                        target: s,
                    });
                });

            // Transition links to their new position.
            link.merge(linkEnter)
                .transition(transition)
                .attr('d', (d) => diagonal({
                    source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                    target: entryOffsetLink(d.target, nodeRender.shape(d.target)),
                }));

            // Transition exiting nodes to the position of the clicked node that is closing.
            link.exit()
                .transition(transition)
                .remove()
                .attr('d', (d) => diagonal({
                    source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                    target: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                }));
            // Stash the old positions for transition.
            root.eachBefore((d) => {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        }

        update();
    }, [root, currentDragNode, triggerRender, containerDimensions]);

    return null;
}

SkosTreeDiagram.propTypes = {
    updateVocab: PropTypes.func, // Call to update the vocabulary data
    updateChart: PropTypes.func, // Function to update the
    updateSideBar: PropTypes.func, // Function to update sidebar
    updateTable: PropTypes.func, // Function to change the scheme displayed in the table
    updateDragNode: PropTypes.func, // Call to change the draggable node
    displayMessage: PropTypes.func, // Display a message to the user such as a warning
    displayContextMenu: PropTypes.func, // Display a context menu for the user
    selectedScheme: PropTypes.array, // Array of scheme identifiers to display in table
    triggerRender: PropTypes.number, // Forces a re-render of the tree after table has updated
    treeId: PropTypes.string, // Unique Id for the tree container in DOM
    dragNodeId: PropTypes.shape({ // Id of the current draggable node, and it's location for entering/exiting
        nodeId: PropTypes.string,
        location: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }),
    containerDimensions: PropTypes.shape({ // The dimensions of the container for the tree
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    zoomPosition: PropTypes.shape({ // The current zoom position of the tree
        k: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
    }),
};

export default SkosTreeDiagram;
