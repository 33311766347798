import React from 'react';

import {
    Box,
    Divider,
    Text,
    List,
    ListItem,
    ListIcon,
    Heading,
    useStyleConfig,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { nodeParams } from './omcDefaults.mjs';

const coreProps = ['identifier', 'schemaVersion', 'entityType', 'name', 'description'];

function indentPropertyValues(omc) {
    const formatProps = (obj, padding = 0, id = 0) => {
        const keys = Object.keys(obj);
        const items = keys.map((k, i) => {
            const lstKey = `${k}-${i}-${id}`;
            if (Array.isArray(obj[k])) {
                return (
                    <ListItem key={lstKey}>
                        {k}
                        {': '}
                        {obj[k].map((d) => (typeof d === 'object' ? formatProps(d, 2, i) : d))}
                    </ListItem>
                );
            }
            if (obj[k] === null) {
                return (
                    <ListItem key={lstKey}>
                        {k}
                        : null
                    </ListItem>
                );
            }
            if (typeof obj[k] === 'object') {
                return (
                    <ListItem key={lstKey}>
                        {`${k} `}
                        <ListIcon as={ArrowForwardIcon} boxSize={4} />
                        {formatProps(obj[k], 3, i)}
                    </ListItem>
                );
            }
            return (
                <ListItem key={lstKey}>
                    {k}
                    {': '}
                    {obj[k]}
                </ListItem>
            );
        });
        return (
            <List pl={padding}>
                {items}
            </List>
        );
    };

    // Filter out the properties that are not intrinsic or core properties
    const properties = omc.entityType === 'Context'
        ? ['contextType'] // Special case Context, because we don't want all the relationships
        : Object.keys(omc)
            .filter((e) => /^[a-z]/.test(e))
            .filter((p) => !coreProps.includes(p));

    const simpleProps = properties.reduce((obj, key) => (
        { ...obj, ...{ [key]: omc[key] } }
    ), {});
    return formatProps(simpleProps);
}

function OmcTreeSideBar({
    omc,
    currentProject,
    projects
}) {
    if (omc === null) return null; // No updates if data is null

    const sideBarBodyStyle = useStyleConfig('SideBarBody');
    const sideBarHeaderStyle = useStyleConfig('SideBarHeader');
    const { entityType } = omc;
    console.log(omc);
    const headerParams = nodeParams[entityType]; // What type of node is this

    const name = omc.getName(); // Get the name of the OMC

    const { identifierScope } = projects[currentProject]; // Identifier for selected project
    const idValue = omc.identifierValueOfScope(identifierScope);

    const indent = indentPropertyValues(omc); // Create indented values

    return (
        <Box w="25%" h="100%">
            <Box __css={sideBarHeaderStyle} backgroundColor={headerParams.style.color}>
                <Heading size="sm" ml={1} mr={2}>{headerParams.label}</Heading>
            </Box>
            <Box __css={sideBarBodyStyle}>
                <Text fontWeight="bold">Name</Text>
                <Text>{name}</Text>
                <Text fontWeight="bold">Description</Text>
                <Text>{(omc.description === '' || !omc.description) ? 'N/A' : omc.description}</Text>
                <Text fontWeight="bold">Identifier</Text>
                <Text>{idValue || 'N/A'}</Text>
                <Divider />
                <Text fontWeight="bold">Properties</Text>
                {indent}
            </Box>
        </Box>
    );
}

export default OmcTreeSideBar;
