import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useToast } from '@chakra-ui/react';

import createOmcDictionary from '../services/vocabulary/omcJsonDictionary.mjs';
import { updateOmcJsonD3Tree } from '../services/vocabulary/omcJsonD3Tree.mjs';
import { sortChildren, createD3Hierarchy, skosD3id } from '../services/vocabulary/d3Utilities.mjs';

export const OmcDictionaryContext = createContext(null);

function OmcDictionaryProvider({ children }) {
    const { authState } = useOktaAuth();
    const [omcEditor, setOmcEditor] = useState({ omcDictionary: null, omcRoot: null });
    const toast = useToast();
    const updateRoot = (dict, root) => {
        setOmcEditor({ omcDictionary: dict, omcRoot: root });
    };

    // Load the OMC Dictionary from the server
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) return;
        const { accessToken } = authState.accessToken;

        const fetchOmc = async () => {
            const omcDictionary = await createOmcDictionary(accessToken);
            // const omcDictionary = await createOmcDictionary('accessToken');
            if (omcDictionary !== null) {
                const omcChart = updateOmcJsonD3Tree(null, omcDictionary); // Create a d3 hierarchy for the tree
                const omcRoot = createD3Hierarchy(omcChart, skosD3id);
                sortChildren(omcRoot);
                setOmcEditor({
                    omcDictionary,
                    omcRoot,
                });
            } else {
                toast({
                    title: 'Server Error',
                    description: 'Dictionary failed to load',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchOmc();
    }, [authState]);

    // const value = useMemo(() => ({
    //     omcEditor,
    //     updateRoot,
    // }), [omcEditor, setOmcEditor]);
    // console.log('OMC Dictionary Context re-rendered');
    // console.log(omcEditor.omcRoot);

    return (
        <OmcDictionaryContext.Provider value={{ omcEditor, updateRoot }}>
            {children}
        </OmcDictionaryContext.Provider>
    );
}

function useOmcDictionary() {
    const value = useContext(OmcDictionaryContext);
    const { omcEditor } = value;
    return omcEditor.omcDictionary || null;
}

function useOmcJsonRoot() {
    const value = useContext(OmcDictionaryContext);
    const { omcEditor } = value;
    return omcEditor.omcRoot || null;
}

export { OmcDictionaryProvider, useOmcDictionary, useOmcJsonRoot };
