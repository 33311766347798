/**
 * Methods for creating and manipulating a row of data in the OMC table
 *
 * @module
 */

/**
 * Create a set of rows for all the types requested
 * @param omcType {string | string[]} - The omc types to be included in the table
 * @param omcDict {object}
 * @param skosDict {object}
 * @returns {(*&{rdfClass: null, skosDefinition: null})[]}
 */

export function createOmcJsonTable(omcType, omcDict, skosDict) {
    const typeId = omcDict.getType(omcType); // All nodes of the specified type

    // Create the row with the SkOS concept if there is one
    return typeId.map((omcId) => {
        const omcEnt = omcDict.getEntity(omcId)
        return {
            ...omcEnt,
            skosDefinition: skosDict.getEntity(omcEnt.skosDefinition),
        }
    })
}

