/**
 * A Set of methods for manipulating the OMC-JSON vocabulary to the SKOS dictionary and definitions
 */
import { customAlphabet } from 'nanoid';

import { getDictionary, postUpdate } from './api.mjs';

import { getNode, getType, getRelated, cacheUpdate, errorResponse } from './dictionaryUtilities.mjs';

const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 10);

/**
 * Generate a new id for a SKOS entity, based on the highest existing id
 *
 * @param offset {number} - The offset to add to the highest existing id (when creating multiple node before committing)
 * @param scope {string} - Scope in which the identifier is valid
 * @return {string}
 */
export function generateId(offset = 1, scope = 'omc') {
    const higherId = (() => {
        const idList = Object.keys(this.nodes)
            .sort();
        let highStr = idList.pop(); // Take the highest result
        highStr = highStr.substring(highStr.lastIndexOf(':') + 1); // Strip the hex number in the id
        const highId = parseInt(Number(`0x${highStr}`), 10); // Convert hex to decimal
        return `${(highId + offset).toString(16)
            .padStart(5, '0')}`.toUpperCase(); // Increment the id
    });

    return `${scope}:${higherId()}`;
}


function omcEntity(omcId) {
    const term = this.getNode(omcId);
    const classId = this.getRelated(omcId, 'represents'); // Which Class represents this omc term
    const rdfClass = classId ? this.getNode(classId) : null;
    const skosId = this.getRelated(omcId, 'hasSkosDefinition');
    const skosDefinition = skosId[0] || null;
    return {
        ...term,
        rdfClass,
        skosDefinition,
    }
}

function omcClass(omcId) {
    const skosId = this.getRelated(omcId, 'hasSkosDefinition');
    const skosDefinition = skosId[0] || null;
    return {
        id: omcId, // User as identifier by ag-grid
        rdfClass: this.getNode(omcId),
        skosDefinition,
    }
}

function omcSchema(omcId) {
    const schema = this.getNode(omcId);
    return {
        ...schema,
        value: this.omcEntity(this.getRelated(omcId, 'hasValue')),
    }
}

/**
 * Build a complete entity including related elements
 * @param omcId {string} - The identifier of the requested entity
 */

const entityTypes = ['omc:Root', 'omc:Entity', 'omc:Property', 'omc:ControlledValue']

function getEntity(omcId) {
    const term = this.getNode(omcId);
    if (term && entityTypes.includes(term.type)) return this.omcEntity(omcId);
    if (term && term.type === 'omc:Schema') return this.omcSchema(omcId);
    if (term && term.type === 'omc:Class') return this.omcClass(omcId);
    return this.getNode(omcId);
}

/**
 * Return child properties, for the requested id
 * @param omcId {string} - The identifier of thw requested node
 * @returns {string[]}
 */

const childMap = {
    'omc:Root': ['hasProperty', 'hasValue'],
    'omc:Container': ['hasProperty'],
    'omc:Entity': ['hasProperty'],
    'omc:Property': ['hasProperty', 'hasControlledValue'],
    'omc:ControlledValue': ['hasSubValue'],
    'omc:Class': ['represents'],
}

function childProperties(omcId) {
    const entity = this.getNode(omcId);
    const relations = childMap[entity.type];
    return this.edges[omcId].filter((n) => relations.includes(n.relation))
        .map((n) => n.targetId);
}

/**
 * Return child properties all the way down the tree
 * @param omcId
 * @returns {*[]}
 */
function allChildProperties(omcId) {
    const children = this.childProperties(omcId);
    return [...children, ...children.flatMap((id) => this.allChildProperties(id))]
}

/**
 * Return parent properties, based on hierarchical view of graph
 * @param nId {string} - The identifier of the requested node
 * @returns {*}
 */

const parentMap = {
    'omc:Root': [],
    'omc:Container': ['propertyOf'],
    'omc:Entity': ['propertyOf'],
    'omc:Property': ['propertyOf'],
    'omc:ControlledValue': ['controlledValueFor', 'subValueFor'],
    'omc:Class': ['representedBy'],
}

function parentProperties(omcId) {
    const entity = this.getNode(omcId);
    const relations = parentMap[entity.type];
    return this.edges[omcId].filter((n) => relations.includes(n.relation))
        .map((n) => n.targetId);
}

/**
 * Make updates to the Neo4J database then internal cache
 * @param action
 * @param accessToken {string}
 */

async function update(action, accessToken) {
    const apiUpdate = await postUpdate(action, 'omc', accessToken); // Update the database
    cacheUpdate.call(this, action) // Update the internal cache
    return apiUpdate;
}

const createNode = ((row) => {
    return row ? {
        id: row.id,
        label: row.label,
        type: row.type,
        status: row.status || null,
    } : null;
});

// Create data for and edge to be used in a transaction
function createEdge(source, target, relation) {
    const sourceNode = typeof source === 'string' ? this.getNode(source) : source;
    const targetNode = typeof target === 'string' ? this.getNode(target) : target;
    return {
        sourceId: sourceNode.id,
        sourceType: sourceNode.type,
        targetId: targetNode.id,
        targetType: targetNode.type,
        relation,
    };
}

/**
 * Check if a node exists as a child in the immediate parent node
 * @type {function(*, *): *}
 */
function existingChild(parentId, id) {
    const currentChildren = this.childProperties(parentId) // Check for duplicates entries
    return currentChildren.find((c) => c === id);
}

/**
 * Check to make sure nodes of conflicting types are not mixed, controlled values can not be mixed with other properties
 * @param parentId
 * @param childId
 * @returns {boolean}
 */
function chkControlledValue(parentId, childId) {
    const currentType = (this.childProperties(parentId))
        .map((cId) => (this.getNode(cId)).type);
    const childType = (this.getNode(childId)).type;
    if (childType === 'omc:Property' && currentType.includes('omc:ControlledValue')) return true;
    if (childType === 'omc:Entity' && currentType.includes('omc:ControlledValue')) return true;
    return !!(childType === 'omc:ControlledValue' &&
        (currentType.includes('omc:Property') || currentType.includes('omc:Entity')));
}

// Update a string value in a term
function stringUpdate({
    oldData, newData, action,
}) {
    const { id } = oldData; // Node id
    const oldNode = this.nodes[id];
    action.update.push({ ...oldNode, ...newData });
}

function updateRdfClass({ oldData, newData, action }) {
    const { rdfClass } = newData;
    if (!rdfClass || !rdfClass.label) return; // Empty class

    rdfClass.label = rdfClass.label.trim(); // Clean up the string

    if (!rdfClass.id) { // Is this a new omc:class
        rdfClass.id = this.generateId(2); // Generate an id and add it.
        rdfClass.type = 'omc:Class';
        action.create.push(createNode(rdfClass));
    } else {
        action.update.push(createNode(rdfClass)); // Update an existing class
    }

    // If the RDF Class has a new id, then delete the relationships for the old one
    if (oldData && (oldData.id && oldData.rdfClass) && (oldData.rdfClass.id !== rdfClass.id)) {
        action.delete.push(
            createEdge.call(this, oldData.id, oldData.rdfClass.id, 'represents'),
            createEdge.call(this, oldData.rdfClass.id, oldData.id, 'representedBy'),);
    }

    // If there is an id a Property or Entity then relate the RDF class to it
    if ((oldData && oldData.type) || (newData.id && newData.id !== 'inputClass')) {
        const propertyId = newData.id ? newData.id : oldData.id; // Varies depending on whether an update or new entry
        const type = newData.type ? newData.type : oldData.type;
        action.create.push({
            sourceId: propertyId,
            sourceType: type,
            targetId: rdfClass.id,
            targetType: 'omc:Class',
            relation: 'represents',
        }, {
            sourceId: rdfClass.id,
            sourceType: 'omc:Class',
            targetId: propertyId,
            targetType: type,
            relation: 'representedBy',
        },);
    }
}

function updateRdfClassAssociation({ oldData, newData, action }) {
    const { rdfClass } = newData;

    // If there was an RDF class associated previously, then delete the relationship
    if (oldData.rdfClass) {
        action.delete.push(
            createEdge.call(this, oldData.id, oldData.rdfClass.id, 'represents'),
            createEdge.call(this, oldData.rdfClass.id, oldData.id, 'representedBy'),
        );
    }

    // If there is an id a Property or Entity then relate the RDF class to it
    if (rdfClass) {
        action.create.push(
            createEdge.call(this, oldData.id, rdfClass.id, 'represents'),
            createEdge.call(this, rdfClass.id, oldData.id, 'representedBy'),
        )
    }
    console.log();
}


function updateClassSkosDefinition({ oldData, newData, action }) {
    const { skosDefinition } = newData;

    let rdfClass = (newData && newData.rdfClass) ? newData.rdfClass : (oldData && oldData.rdfClass) ? oldData.rdfClass : null;

    // Delete an existing class definition if there is one
    if (oldData && oldData.skosDefinition && oldData.rdfClass) {
        action.delete.push(createEdge.call(this, oldData.rdfClass, oldData.skosDefinition, 'hasSkosDefinition'),);
    }
    // If there is a skos Definition and class to relate, then add that relationship
    if (skosDefinition && rdfClass) {
        action.create.push(createEdge.call(this, rdfClass, skosDefinition, 'hasSkosDefinition'),);
    }
}

/**
 * Update the SKOS definition for a term
 */
function updateTermSkosDefinition({ oldData, newData, action }) {
    const { skosDefinition } = newData;

    let node = (newData && newData.id)
        ? { id: newData.id, type: newData.type } // A new input
        : this.getNode(oldData.id); // A change to an existing node

    // Delete an existing relationship if there is one
    if (oldData && oldData.skosDefinition) {
        action.delete.push(createEdge.call(this, oldData, oldData.skosDefinition, 'hasSkosDefinition'),);
    }
    // If there is a skos Definition and class to relate, then add that relationship
    if (skosDefinition) {
        action.create.push({
                sourceId: node.id,
                sourceType: node.type,
                targetId: skosDefinition.id,
                targetType: skosDefinition.type,
                relation: 'hasSkosDefinition',
            },
        );
    }
}

function updateSkosDefinition(params) {
    const { oldData } = params;
    return (!oldData.type || oldData.type === 'omc:Class') // Not type or the Class both indicate a Class
        ? updateClassSkosDefinition.call(this, params) : updateTermSkosDefinition.call(this, params);
}

function updateLabel({ oldData, newData, action }) {
    newData.id = (!oldData || !oldData.id) ? this.generateId(1) : oldData.id; // A new entry or existing
    action.create.push(createNode(newData));
}

/**
 * Add a new property, entity or controlled value
 * @param oldData
 * @param newData
 * @param action
 * @returns {{action, error: null}}
 */
function addPropRow({ oldData, newData, action }) {
    const label = newData.label ? newData.label.trim() : '';
    // Do not allow empty labels
    if (!label || label === '') return this.errorResponse('Must include a valid label');

    // Do not allow Property labels with duplicate names
    const { type } = newData;
    const existingLabel = [...this.getType(type)]
        .map((nId) => (this.getNode(nId)).label); // Existing class labels

    if (existingLabel.includes(newData.label)) {
        return this.errorResponse('Label with this name already exists'); // Return unchanged data
    }
    updateLabel.call(this, { oldData, newData, action }); // Add a label if there is one
    updateRdfClass.call(this, { oldData, newData, action }); // Add and relate the rdf class
    updateTermSkosDefinition.call(this, { oldData, newData, action }); // Add and relate the skos definition

    return {
        error: null,
        action,
    };
}

/**
 * Add a new Class
 * @param oldData
 * @param newData
 * @param action
 * @returns {{action, error: null}}
 */
function addClassRow({ oldData, newData, action }) {
    const { rdfClass } = newData;
    const label = rdfClass.label ? rdfClass.label.trim() : '';
    // Validate the input, to ensure there is valid data
    if (!rdfClass || label === '') {
        return this.errorResponse('Must include a label for the Class'); // Return unchanged data
    }
    // Do not allow Classes with duplicate names, or empty strings
    const existingClasses = this.getType('omc:Class')
        .map((nId) => (this.getNode(nId)).label); // Existing class labels
    if (existingClasses.includes(newData.rdfClass.label)) {
        return this.errorResponse('Class with this name already exists');
    }

    updateRdfClass.call(this, { oldData, newData, action }); // Add and relate the rdf class
    updateClassSkosDefinition.call(this, { oldData, newData, action }); // Add and relate the skos definition

    return {
        error: null,
        action
    };
}

function deleteNode({ oldData }) {
    // Return an error if the term is currently being referred to by a schema node
    const inSchema = [
        ...this.childProperties(oldData.id),
        ...this.parentProperties(oldData.id),
    ]
    if (inSchema.length) {
        return this.errorResponse(`The value is currently used, remove from schema before deleting'`)
    }

    // Okay to delete this term
    return {
        error: null,
        action: {
            'delete': [oldData],
        },
    };
}

/**
 * Update any edits to the label of a controlled value, this must percolate through the schema with the dot notation
 * @param newData
 * @param oldData
 * @param action
 */

function fieldChange({ oldData, newData, action }) {
    const updatePattern = {
        label: stringUpdate,
        status: stringUpdate,
        rdfClass: updateRdfClass,
        rdfAssociation: updateRdfClassAssociation,
        skosDefinition: updateSkosDefinition,
    };
    const field = Object.keys(newData)[0];
    if (updatePattern[field]) {
        updatePattern[field].call(this, { oldData, newData, action });
        return {
            error: null,
            action,
        };
    }
    return this.errorResponse(`Internal error: No update pattern found for ${updatePattern}`); // Return unchanged data
}

function insertProperty({ parent, node }) {
    if (existingChild.call(this, parent.id, node.id)) {
        return this.errorResponse(`The Controlled Value with the label '${existingChild.label}' already exists`)
    }
    if (chkControlledValue.call(this, parent.id, node.id)) {
        return this.errorResponse('Controlled values can not be mixed with other property types')
    }

    return {
        error: null,
        action: {
            create: [
                createEdge.call(this, parent.id, node.id, 'hasProperty'),
                createEdge.call(this, node.id, parent.id, 'propertyOf'),
            ],
        },
    };
}

/**
 * Remove a property from the OMC-JSON vocabulary
 * @param parent
 * @param node
 * @param action
 * @returns {{action, error: null}|{error: {description, title: string, status: string}}}
 */

function removeProperty({
    parent, node, action,
}) {
    return {
        error: null,
        action: {
            'delete': [
                createEdge.call(this, parent.id, node.id, 'hasProperty'),
                createEdge.call(this, node.id, parent.id, 'propertyOf'),
            ]
        }
    };
}

/**
 * Remove a controlled value from the OMC-JSON vocabulary
 * @param parent
 * @param node
 * @param action
 * @returns {{action, error: null}|{error: {description, title: string, status: string}}}
 */

function removeControlledValue({ parent, node, action }) {
    // Recurse down the entire chain of children and detach them
    const removeChildren = (n) => {
        const children = this.childProperties(n.id);
        children.forEach((c) => {
            const relationTo = n.type === 'omc:Property' ? 'hasControlledValue' : 'hasSubValue';
            const relationFrom = n.type === 'omc:Property' ? 'controlledValueFor' : 'subValueFor';
            action.delete.push(
                createEdge.call(this, c.id, n.id, relationTo),
                createEdge.call(this, n.id, c.id, relationFrom),
            );
        })
    }

    const subValueFor = this.getRelated(node.id, 'subValueFor') // Property can be sub-value for multiple
    if (subValueFor.length === 1) removeChildren(node); // If this is the only instance detach all children

    const relationTo = parent.type === 'omc:Property' ? 'hasControlledValue' : 'hasSubValue';
    const relationFrom = parent.type === 'omc:Property' ? 'controlledValueFor' : 'subValueFor';
    return {
        error: null,
        action: {
            'delete': [
                createEdge.call(this, parent.id, node.id, relationTo),
                createEdge.call(this, node.id, parent.id, relationFrom),
            ]
        }
    };
}


/**
 * Insert a omc:ControlledValue
 * @param parent
 * @param node
 * @returns {{error: {}}| {action, error: null}}
 */
function insertControlledValue({ parent, node }) {
    if (existingChild.call(this, parent.id, node.id)) {
        return this.errorResponse(`The Controlled Value with the label '${existingChild.label}' already exists`)
    }
    if (chkControlledValue.call(this, parent.id, node.id)) {
        return this.errorResponse('Controlled values can not be mixed with other property types')
    }

    const relationTo = parent.type === 'omc:Property' ? 'hasControlledValue' : 'hasSubValue';
    const relationFrom = parent.type === 'omc:Property' ? 'controlledValueFor' : 'subValueFor';
    return {
        error: null,
        action: {
            create: [
                createEdge.call(this, parent.id, node.id, relationTo),
                createEdge.call(this, node.id, parent.id, relationFrom),
            ],
        }
    };
}

// Copy a node in the chart requires no action to the database
function copyProperty() {
    return {
        error: null,
        action: {}
    };
}

/**
 * Execute the appropriate method when a change request is made
 * @param updateAction
 * @param params
 * @returns {*}
 */

function changeOMCRequest(updateAction, params) {
    const updateFunctions = {
        fieldChange,
        inputProp: addPropRow,
        inputClass: addClassRow,
        insertProperty,
        insertControlledValue,
        deleteNode,
        removeProperty,
        copyProperty,
        removeControlledValue,
        moveProperty: removeProperty,
        moveControlledValue: removeControlledValue,
    };
    const action = {
        create: [], update: [], delete: [],
    };
    console.log(`Update request: ${updateAction}`);
    if (updateFunctions[updateAction]) {
        const updateRequest = updateFunctions[updateAction].call(this, {
            ...params, action,
        });
        return updateRequest;
    }
    return this.errorResponse(`Internal error: No update function found for ${updateAction}`);
}

export default async function createOmcDictionary(accessToken) {
    const dict = await getDictionary('omc', accessToken);
    const dictProto = Object.create({
        getNode,
        getType,
        getRelated,
        errorResponse,
        omcClass,
        omcEntity,
        omcSchema,
        getEntity,
        childProperties,
        allChildProperties,
        parentProperties,
        generateId,
        update,
        changeOMCRequest,
    });
    return dict
        ? Object.assign(dictProto, dict)
        : null; // For error condition
}
