/**
 * Use Ag-Grid to display the SKOS table
 */

import React, {
    useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    IconButton,
    Icon,
    Stack,
    RadioGroup,
    Radio,
    Container,
    Spacer,
} from '@chakra-ui/react';
import { AiOutlineMinusCircle, AiOutlinePartition, AiOutlinePlusCircle } from 'react-icons/ai';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { LicenseManager } from '@ag-grid-enterprise/core';

import '@ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import '@ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import { createOmcJsonTable } from '../../../services/vocabulary/omcTable.mjs';
import { useSkosDictionary } from '../../../hooks/SkosDictionaryContext';
import { useOmcDictionary } from '../../../hooks/OmcDictionaryContext';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    ExcelExportModule,
    MenuModule,
]);

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-059153}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{MovieLabs}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MovieLabs}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{MovieLabs}_need_to_be_licensed___{MovieLabs}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[01]_MTc0NTUzNTYwMDAwMA==b1fbd528fc257fa86809087a66529682');

const inputPrompts = {
    action: '',
    label: 'Label...',
    id: 'N/A',
    rdfClassId: 'N/A',
    rdfClass: 'Label...',
    status: 'Status...',
    skosDescription: 'N/A',
    skosDefinition: 'Concept...',
};

function OmcJsonTable({
    updateChart = () => {}, // Call to update the chart
    updateTable = () => {}, // Force the table to re-render
    forceTableRender = () => {}, // A callback to re-render the sidebar when the table is updated
    updateSideBar = () => {}, // Call to update sidebar when data has changed in table
    changeDragNode = () => {}, // Allows a button in the table to add a drag node to the graph
    displayMessage = () => {}, // Display an error message to the user
    tableContainerSize = { width: 0, height: 0 },
}) {
    const [rowData, setRowData] = useState([]);
    const [omcJsonType, setOmcJsonType] = useState('omc:Entity');
    const emptyRow = {
        id: omcJsonType === 'N/A',
        type: omcJsonType,
        label: null,
        status: null,
        rdfClass: null,
        skosDefinition: null,
    };

    const [inputRow, setInputRow] = useState(emptyRow); // The input row for adding new data
    const [colLayout, setColLayout] = useState('property');
    const [labelHeaderName, setLabelHeaderName] = useState('OMC Label');
    const skosDictionary = useSkosDictionary();
    const omcDictionary = useOmcDictionary();

    const concepts = skosDictionary.getType('skos:Concept');
    const skosValues = concepts.map((nId) => skosDictionary.getEntity(nId))
        .sort((a, d) => a.prefLabel.value.localeCompare(d.prefLabel.value));
    const rdfClassValues = (omcDictionary.getType('omc:Class')).map((nId) => omcDictionary.getNode(nId))
        .sort((a, d) => a.label.localeCompare(d.label));

    const defaultColDef = useMemo(() => ({
        valueFormatter: ((params) => {
            if (params.node.rowPinned !== 'top') return undefined;
            const { field } = params.colDef;
            return (typeof params.value === 'string' && params.value !== '')
                ? params.value
                : inputPrompts[field] || `${field}...`;
        }),
    }));

    // Process the request to update the vocabulary, handle errors and updating the UI
    const processRequest = (async (actionVerb, params) => {
        const updateResponse = omcDictionary.changeOMCRequest(actionVerb, params); // Update the core data

        if (updateResponse.error) {
            displayMessage(updateResponse.error);
        } else {
            const dbResponse = await updateChart({
                action: updateResponse.action,
            });
            updateTable(null);
            updateSideBar(true); // Update the sidebar to reflect any edits made
        }
    });

    // Some inputs require and object to be passed in as the value, this function maps the input to the correct object
    const fieldValueMap = {
        rdfClass: (val, oldData) => {
            const exist = rdfClassValues.find((refData) => refData.label === val);
            return exist || { ...oldData.rdfClass, ...{ label: val } }; // Label is being updated, id stays same
        },
        rdfAssociation: ((val) => rdfClassValues.find((refData) => refData.label === val)),
        // Return the skos definition object, or null if the selection value is '(clear)'
        skosDefinition: ((val) => skosValues.find((refData) => refData.prefLabel.value === val)),
    };

    // Called when cell editing is complete, the event passes the new value in for processing
    const onCellEditRequest = useCallback((async (event) => {
        const oldData = event.data;
        const { field } = event.colDef;

        const newValue = fieldValueMap[field] // Convert input string to object for certain fields
            ? fieldValueMap[field](event.newValue, oldData)
            : event.newValue;

        const changeField = (field === 'rdfAssociation') ? 'rdfClass' : field; // rdfClass is the field name in the table
        const newData = { ...oldData, ...{ [changeField]: newValue } }; // Clone the existing data

        if (event.rowPinned !== 'top') { // Is this a new item, or existing data
            await processRequest('fieldChange', {
                oldData,
                newData: { [changeField]: newValue },
            });
        }
        setInputRow(newData); // Update the data in the row
    }));

    // Called when a new term is submitted
    const submitNewTerm = (async (params) => {
        const newData = params.data;
        const actionVerb = newData.type === 'omc:Class' ? 'inputClass' : 'inputProp';
        await processRequest(actionVerb, {
            oldData: null,
            newData,
        });
    });

    // Called when a term is removed
    const removeTerm = (async (input) => {
        const entity = input.type ? input : input.rdfClass; // No type in the input for omc:Class
        await processRequest('deleteNode', {
            oldData: entity,
        });
    });

    const customButtonRenderer = ((props) => {
        const { node } = props;
        return node.rowPinned === 'top'
            ? (
                <IconButton
                    variant="outline"
                    size="sm"
                    aria-label="Submit"
                    icon={<Icon as={AiOutlinePlusCircle} w={6} h={6} />}
                    onClick={() => submitNewTerm(props)}
                />
            )
            : (
                <Box>
                    <IconButton
                        variant="ghost"
                        size="sm"
                        aria-label="Position"
                        icon={<Icon as={AiOutlineMinusCircle} w={6} h={6} />}
                        onClick={() => removeTerm(props.data)}
                    />
                    <IconButton
                        variant="ghost"
                        size="sm"
                        aria-label="Position"
                        icon={<Icon as={AiOutlinePartition} w={6} h={6} />}
                        onClick={() => changeDragNode(props.data.id)}
                    />
                </Box>
            );
    });

    const getRowStyle = useCallback(({ node }) => (
        node.rowPinned ? {
            fontWeight: 'bold',
            fontStyle: 'italic',
            backgroundColor: '#85C1E9',
        } : {}
    ), []);

    // Update the table to use the correct set of columns and populate the rows with correct data
    useEffect(() => {
        if (!omcJsonType) return; // No table data yet
        const omcRows = createOmcJsonTable(omcJsonType, omcDictionary, skosDictionary);
        setRowData(omcRows);
        setInputRow({ ...emptyRow, ...{ type: omcJsonType } });
        const cLayout = omcJsonType === 'omc:Class' ? 'class' : 'property'; // Which arrangement of columns
        setColLayout(cLayout);
        const labelType = Array.isArray(omcJsonType) ? 'OMC Label' : omcJsonType.replace('omc:', '');
        setLabelHeaderName(omcJsonType === 'omc:Class' ? 'Class Label' : `${labelType} Label`);
    }, [omcJsonType, forceTableRender]);

    const columnDefinitions = {
        property: [
            {
                field: 'action',
                headerName: '',
                cellRenderer: customButtonRenderer,
            },
            {
                field: 'label',
                headerName: labelHeaderName,
                editable: true,
                sortable: true,
                filter: true,
            },
            {
                field: 'id',
                headerName: 'OMC ID',
            },
            {
                field: 'type',
                sortable: true,
                hide: true,
            },
            {
                field: 'rdfAssociation',
                headerName: 'RDF Class',
                editable: true,
                valueGetter: ((p) => (p.data.rdfClass ? p.data.rdfClass.label : '')),
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                    values: ['(clear)', ...rdfClassValues.map((n) => n.label)],
                    allowTyping: true,
                    searchType: 'matchAny',
                    filterList: true,
                    highlightMatch: true,
                    valueListMaxHeight: 220,
                },
            },
            {
                field: 'status',
                sortable: true,
                editable: true,
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                    values: ['published', 'review', 'proposed', 'omc-schema', 'deprecated'],
                },
            },
            {
                field: 'skosDefinition',
                headerName: 'SKOS Concept',
                editable: true,
                valueGetter: ((p) => (p.data.skosDefinition ? p.data.skosDefinition.prefLabel.value : '')),
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                    values: ['(clear)', ...skosValues.map((n) => n.prefLabel.value)],
                    allowTyping: true,
                    searchType: 'matchAny',
                    filterList: true,
                    highlightMatch: true,
                    valueListMaxHeight: 220,
                    valuePlaceholder: '(Empty)',
                },
            },
            {
                field: 'skosDescription',
                headerName: 'SKOS Definition',
                valueGetter: ((p) => (p.data.skosDefinition ? p.data.skosDefinition.definition : '')),
            },
        ],
        class: [
            {
                field: 'action',
                headerName: '',
                cellRenderer: customButtonRenderer,
            },
            {
                field: 'rdfClass',
                headerName: 'Class Label',
                editable: true,
                valueGetter: ((p) => (p.data.rdfClass ? p.data.rdfClass.label : '')),
            },

            {
                field: 'rdfClassId',
                headerName: 'Class ID',
                valueGetter: ((p) => (p.data.rdfClass ? p.data.rdfClass.id : 'N/A')),
            },
            {
                field: 'type',
                sortable: true,
                valueGetter: ((p) => (p.data.rdfClass ? p.data.rdfClass.type : 'N/A')),
            },
            {
                field: 'skosDefinition',
                headerName: 'SKOS Concept',
                editable: true,
                valueGetter: ((p) => (p.data.skosDefinition ? p.data.skosDefinition.prefLabel.value : '')),
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                    values: ['(clear)', ...skosValues.map((n) => n.prefLabel.value)],
                    valuePlaceholder: 'skos concept...',
                    searchType: 'matchAny',
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                    valueListMaxHeight: 220,
                },
            },
            {
                headerName: 'SKOS Identifier',
                field: 'skosDefinition',
                valueGetter: ((p) => (p.data.skosDefinition ? p.data.skosDefinition.id : 'N/A')),
                hide: true,
            },
            {
                field: 'skosDefinition',
                headerName: 'SKOS Definition',
                valueGetter: ((p) => (p.data.skosDefinition ? p.data.skosDefinition.definition : 'N/A')),
            },
        ],
    };

    const gridParams = {
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
        rowData,
        columnDefs: columnDefinitions[colLayout],
        defaultColDef,
        readOnlyEdit: true,
        onCellEditRequest,
        reactiveCustomComponents: true,
        // getContextMenuItems,
        getRowId: (params) => params.data.id,
        pinnedTopRowData: [inputRow],
        getRowStyle,
    };

    return (
        <Box m={1} h={tableContainerSize.height}>
            <Box borderWidth="1px" borderRadius="lg" p={2}>
                <Container>
                    <Stack direction="row">
                        <RadioGroup onChange={setOmcJsonType} value={omcJsonType}>
                            <Radio value="omc:Class">Class</Radio>
                        </RadioGroup>
                        <Spacer />
                        <RadioGroup onChange={setOmcJsonType} value={omcJsonType}>
                            <Stack direction="row">
                                <Radio value="omc:Container">Container</Radio>
                                <Radio value="omc:Entity">Entity</Radio>
                                <Radio value="omc:Property">Property</Radio>
                                <Radio value="omc:ControlledValue">Controlled Value</Radio>
                            </Stack>
                        </RadioGroup>
                    </Stack>
                </Container>
            </Box>
            <Box
                mt={1}
                className="ag-theme-quartz" // applying the grid theme
                h={tableContainerSize.height - 44} // Table container less the selection bar (this is a hack)
            >
                <AgGridReact
                    {...gridParams}
                    modules={[
                        ClientSideRowModelModule,
                        RichSelectModule,
                    ]}
                />
            </Box>
        </Box>
    );
}

OmcJsonTable.propTypes = {
    updateChart: PropTypes.func, // Call to update the chart
    updateSideBar: PropTypes.func, // Function to update sidebar
    updateTable: PropTypes.func, // Call to change the draggable node
    forceTableRender: PropTypes.func, // Call to update the vocabulary data
    displayMessage: PropTypes.func, // Display a message to the user such as a warning
    changeDragNode: PropTypes.func, // Display a context menu for the user
    tableContainerSize: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
    }),
};

export default React.memo(OmcJsonTable);
