import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import {
    Box,
    Container,
    Flex,
    Center,
    Spinner,
    Accordion,
    AccordionPanel,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
} from '@chakra-ui/react';

// Create a line outputting a single claim and it's value
function Claim({
    claim,
    value,
}) {
    return (
        <Flex>
            <Box flex="2">
                {claim}
            </Box>
            <Box flex="4">
                {value}
            </Box>
        </Flex>
    );
}

Claim.propTypes = {
    claim: PropTypes.string,
    value: PropTypes.string,
};

Claim.defaultProps = {
    claim: 'Empty',
    value: 'Empty',
};

/*
 Log a user in and display their profile with the claims they have
 */

function Profile() {
    const {
        authState,
        oktaAuth,
    } = useOktaAuth();
    const [userIdToken, setIdToken] = useState(null);
    const [userAccessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const fetchOktaInfo = async () => {
            // const info = await oktaAuth.getUser();
            const authTokens = await oktaAuth.token.getWithoutPrompt();
            const {
                idToken,
                accessToken,
            } = authTokens.tokens;
            try {
                // const valid = await oktaAuth.token.verify(idToken);
                console.log('Id Token');
                console.log(idToken);
                console.log('Access Token');
                console.log(accessToken);
            } catch (err) {
                console.log(err);
            }

            setAccessToken(accessToken);
            setIdToken(idToken);
        };

        if (!authState.isAuthenticated) {
            setIdToken(null); // When user isn't authenticated, forget any user info
        } else {
            fetchOktaInfo();
        }
    }, [authState, oktaAuth]); // Update if authState changes

    // If the user is not yet authorized return the spinner
    if (!userIdToken) {
        return (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        );
    }
    const accessClaims = userAccessToken.claims;
    const userClaims = userIdToken.claims;
    const issued = new Date(accessClaims.iat * 1000).toISOString();
    const expires = new Date(accessClaims.exp * 1000).toISOString();
    const labkoatClaims = Object.keys(accessClaims)
        .filter((key) => key.includes('labkoat'))
        .map((key) => (<Claim key={`jwtclm-${key}`} claim={key} value={accessClaims[key].join(', ')} />));
    return (
        <Box>
            <svg width="100%" height="120px">
                <path d="M60,15 L60,110 M30,40 L90,40 M30,75 L90,75 M30,110 L90,110" stroke="grey" />
                <g>
                    <rect x="0" y="0" width="120" height="120" fill="none" stroke="grey" />
                    <text textAnchor="end" x="60" y="40">Test</text>
                    <text textAnchor="start" x="60" y="60">Test</text>
                </g>
            </svg>
            <Box p={4}>
                <Container maxW="container.xl" border="1px" borderRadius="md" mb={2} p={4} borderColor="gray.200">
                    <Claim claim="Claim" value="Value" />
                    <Claim claim="name" value={userClaims.name} />
                    <Claim claim="email" value={userClaims.email} />
                    <Claim claim="sub" value={userClaims.sub} />
                    <Claim claim="iat" value={issued} />
                    <Claim claim="exp" value={expires} />
                    {labkoatClaims}
                </Container>
                <Container maxW="container.xl" border="1px" borderRadius="md" mt={2} p={4} borderColor="gray.200">
                    <Accordion allowMultiple>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        Access Token
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel>
                                {oktaAuth.getAccessToken()}
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        Id Token
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel>
                                {oktaAuth.getIdToken()}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Container>
            </Box>
        </Box>
    );
}

export default Profile;
