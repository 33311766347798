import { hierarchy } from 'd3-hierarchy';
import { linkHorizontal } from 'd3-shape';
import { select } from 'd3-selection';

export const nodeData = ((n) => (n.data.type === 'omc:Schema' ? n.data.value : n.data));

const nodeType = ((n) => (n.data.type === 'omc:Schema' ? n.data.value.type : n.data.type));

function shape(node) {
    const type = nodeType(node);
    const { shape } = this[type].style || this.Default.style;
    return shape;
}

function expand(node) {
    const type = nodeType(node);
    const { expand } = this[type].style || this.Default.style;
    return expand;
}

function fillColor(node) {
    const type = nodeType(node);
    return node.draggable
        ? this[type].style.dragColor || this.Default.style.dragColor
        : this[type].style.color || this.Default.style.color;
}

function targetColor(node) {
    const type = nodeType(node);
    return this[type].style.dragColor || this.Default.style.dragColor;
}

function statusColor(node, colors, dict) {
    const n = dict.getNode((nodeData(node)).id);
    return (!n || !n.status || n.status === 'published')
        ? null
        : colors[n.status];
}

function jsonPrefLabel(node, dict) {
    const value = nodeData(node);
    const n = dict.getEntity(value.id);
    return n.label;
}

function skosPrefLabel(node, dict) {
    const value = nodeData(node);
    const n = dict.getEntity(value.id);
    return  n ? n.prefLabel.value : 'Blank';
}

// Return shape parameter for a given node type
function contextMenu(node) {
    const type = node.draggable ? 'draggable' : nodeType(node); // Special case for draggable nodes
    const { contextMenu } = this[type] || this.Default;
    return contextMenu;
}

export function omcJsonParams(nodeParams) {
    const proto = Object.create({
        shape,
        expand,
        fillColor,
        targetColor,
        statusColor,
        prefLabel: jsonPrefLabel,
        contextMenu,
    });
    return Object.assign(proto, nodeParams);
}

export function skosParams(nodeParams) {
    const proto = Object.create({
        shape,
        expand,
        fillColor,
        targetColor,
        statusColor,
        prefLabel: skosPrefLabel,
        contextMenu,
    });
    return Object.assign(proto, nodeParams);
}

/**
 * D3 function for calculating coordinate of link
 */
export const diagonal = linkHorizontal()
    .x((d) => d.y)
    .y((d) => d.x);

/**
 * Calculate exit coordinates for link based on the nodes shape
 * @type {function(*, *): {x: *, y}}
 */
export const exitOffsetLink = ((source, shape) => ({
    x: source.x, y: source.y + shape.width / 2,
}));

/**
 * Calculate entry coordinates for link based on the nodes shape
 * @type {function(*, *): {x: *, y}}
 */
export const entryOffsetLink = ((source, shape) => ({
    x: source.x, y: source.y - shape.width / 2
}));

export const dragNodePosition = ((zoomPosition, containerWidth, containerHeight) => {
    const {
        x: zoomX, y: zoomY, k: zoomK,
    } = zoomPosition; // Position the new node on screen adjusted for zoom
    const zoomFactor = 1 / zoomK;
    return {
        xPos: (containerHeight - zoomY - 40) * zoomFactor,
        yPos: (containerWidth / 2 - zoomX) * zoomFactor,
    }
});

/**
 * Calculate the height of the tree
 * @type {function(*, number=): number}
 */
export const treeHeight = ((node, nHeight = 0) => {
    let h = nHeight + 1;
    if (node.children) {
        const branchHeight = node.children.map((c) => treeHeight(c, h));
        const maxHeight = Math.max(...branchHeight);
        h = maxHeight > h ? maxHeight : h;
    }
    return h;
});

export const hasCollided = ((xDif, yDif, shape) =>
    (xDif > (-shape.height / 2) && xDif < (shape.height / 2)) && (yDif > (-shape.width / 2) && yDif < (shape.width / 2)));

/**
 * Set a node to be draggable, including raising it to the top of the stack
 * @param e
 * @param d
 */
export function dragStarted(e, d) {
    if (!d.draggable) return;
    select(this)
        .raise();
}

// Translate the dragged coordinates into the base coordinates system, switch x & y
export const dragCoords = ((d) => ([d.x0 + (d.y - d.y0), d.y0 + (d.x - d.x0)]));

// Calculate the transform for a dragged node
export const dragNodeTransform = ((d, nodeRender) => {
    const shape = nodeRender.shape(d);
    const [dragX, dragY] = dragCoords(d);
    return `translate(${dragY - shape.width / 2}, ${dragX - shape.height / 2})`;
});

// Called for node being dragged, applies the new transform as it is moved
// export function dragged(e, d) {
export function dragged({ event, dragNode, nodeRender, canDrop }) {
    if (!dragNode.draggable) return; // Only do this for draggable nodes

    // Update the dragged nodes position
    dragNode.x += event.dx; // Update the coords from the event
    dragNode.y += event.dy;
    select(this).attr('transform', dragNodeTransform(dragNode, nodeRender)); // Apply the new transform

    // Highlight nodes when the dragged node is over them and meets criteria for dropping
    const { nodes } = dragNode;
    const { type } = dragNode.data;
    const [dragX, dragY] = dragCoords(dragNode);
    const shape = nodeRender.shape(dragNode);
    const candidateType = canDrop[type]; // Only nodes of the correct type can be dropped on
    const candidateNodes = nodes.filter((n) => candidateType.includes((nodeData(n)).type));

    candidateNodes.forEach((n) => {
        const xDif = n.x - dragX;
        const yDif = n.y - dragY;
        select(`#${n.d3id} rect`) // Set a nodes stoke color, if the drag node is over it
            .attr('filter', hasCollided(xDif, yDif, shape)
                ? `drop-shadow(0px 0px 5px ${nodeRender.targetColor(n)})`
                : null);
    });
}

/**
 * Create a d3 hierarchy with D3 node objects and vocabulary tree properties
 * @param root
 * @param uniqueD3id {function} - Function to generate a unique d3id for the node
 * @returns {*}
 */
export function createD3Hierarchy(root, uniqueD3id) {
    const d3Hierarchy = hierarchy(root);

    // Traverse down the tree and add d3 properties
    const traverse = ((node, parentId = null) => {
        node.draggable = false;
        const d3id = uniqueD3id(node, parentId);
        node.d3id = d3id
        if (node.children) {
            node.children.forEach((child) => traverse(child, d3id));
        }
        return node;
    })

    // Create the root node and descendents
    const rootNode = traverse(d3Hierarchy)

    // Return child nodes closed
    rootNode.descendants()
        .forEach((d) => {
            d._children = d.children;
            if (d.depth) d.children = null;
        });

    return rootNode;
}

/**
 * Create a single D3 node for the vocabulary tree
 * @param nodeId
 * @param dict
 * @param uniqueD3id
 * @param coords {x: number, y: number, x0: number, y0: number, draggable: boolean}
 * @return {*}
 */
export function createD3Node(nodeId, dict, uniqueD3id, coords = {}) {
    const d3Node = createD3Hierarchy(dict.getEntity(nodeId), uniqueD3id); // Build new node with data
    d3Node.x = coords.x || 0;
    d3Node.y = coords.y || 0;
    d3Node.x0 = coords.x0 || 0;
    d3Node.y0 = coords.y0 || 0;
    d3Node.draggable = coords.draggable || false;
    return d3Node;
}

/**
 * Recurse down the dictionary tree adding the child properties
 * @param rootId
 * @param dict
 * @returns {*}
 */

export function nodeChildren(rootId, dict, maxDepth = 100) {
    const propertyTree = ((nId, depth = 0) => {
        if (depth > maxDepth) return null;
        return nId.map((eId) => {
            return {
                ...dict.getEntity(eId),
                children: propertyTree(dict.childProperties(eId), depth + 1)
            }
        }).filter((t) => !!t)
    });

    const rId = Array.isArray() ? rootId : [rootId]
    return propertyTree(rId)[0];
}

/**
 * Recursively expand the children of the tree as needed based on what nodes are open in the current view
 * @param rootId
 * @param openChildren
 * @param dict
 * @returns {*}
 */
export function expandChildren(rootId, openChildren, dict) {
    const propertyTree = ((nId, parentId = null) => {
        return nId.map((eId) => {
            const d3id = (parentId ? `${parentId}-${eId}` : eId).replace(':', '_');
            return openChildren[d3id]
                ? { // If node is open, recurse down tree to get children
                    ...dict.getEntity(eId),
                    children: propertyTree(dict.childProperties(eId), d3id)
                }
                : { // If node is closed, do not recurse down tree
                    ...dict.getEntity(eId),
                    children: (dict.childProperties(eId).map((cId) => dict.getEntity(cId))),
                };
        }).filter((t) => !!t);
    });

    const rId = Array.isArray() ? rootId : [rootId]
    return propertyTree(rId)[0];
}

/**
 * Return descendents (children) of the node passed as root
 * @param root
 * @returns {*}
 */

export function allDescendents(root) {
    return root.flatMap((n) => {
        const children = n._children ? allDescendents(n._children) : []
        return [...children, n];
    })
}

/**
 * Set depths for each node in the tree, starting at the parent and through the children
 * parent {object} - The parent node
 * node {object} - The node on which to set the depth
 */
export function depthD3Tree(parent, node) {
    node.depth = parent.depth + 1 || 0;
    node.parent = parent;
    if (node._children) {
        node._children.forEach((child) => depthD3Tree(node, child));
    }
}

// Generate D3 id for the OMC JSON tree
export const omcD3id = ((node) => node.data.id.replace(':', '_'));

// Generate D3 id for the SKOS tree
export const skosD3id = ((node, parentId) => (parentId ? `${parentId}-${node.data.id}` : node.data.id).replace(':', '_'));

/**
 * Sort the children of each node, sort algorithm is specific to the type of the node
 */

const sortPrefLabel = ((a, b) => (a.data.prefLabel.value.localeCompare(b.data.prefLabel.value)));
const sortLabel = ((a, b) => (a.data.label.localeCompare(b.data.label)));

const sortAlgorithm = {
    'omc:Schema': ((a, b) => (`${a.data.value.type}-${a.data.label}`.localeCompare(`${b.data.value.type}-${b.data.label}`))),
    'omc:Root': sortPrefLabel,
    'omc:Container': sortLabel,
    'omc:Entity': sortLabel,
    'omc:Property': sortLabel,
    'omc:ControlledValue': sortLabel,
    'skos:Concept': sortPrefLabel,
    'skos:ConceptScheme': sortPrefLabel,
};

export function sortChildren(node) {
    if (node._children) {
        node._children.sort((a, b) => (sortAlgorithm[a.data.type](a, b)));
        if (node.children) node.children = node._children;
        node._children.forEach((c) => sortChildren(c));
    }
}

/**
 * Creates a map of the currently open children in the tree, which is used to restore the tree state
 * Passing openChildren will set those nodes to the state you pass in, allowing nodes to be open or closed
 * @param node
 * @param openChildren
 * @returns {{}}
 */

export function checkOpenChildren(node, openChildren = {}) {
    if (node.children) {
        openChildren[node.d3id] = openChildren.hasOwnProperty(node.d3id) ? openChildren[node.d3id] : true;
        node.children.forEach((child) => checkOpenChildren(child, openChildren));
    }
    return openChildren;
}

/**
 * Set the children of the node to be open based on the previously created state from openChildren map
 * @param node
 * @param openChildren
 * @returns {*}
 */

export function setOpenChildren(node, openChildren) {
    sortChildren(node); // Sort the children in the entire tree
    if (openChildren[node.d3id] && node._children) {
        node.children = node._children;
        node.children.forEach((child) => setOpenChildren(child, openChildren));
    }
    return node;
}
